import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LegendeZandhovenComponent} from './legende-zandhoven/legende-zandhoven.component';
import {HomeComponent} from './home/home.component';
import {CookieService} from 'ngx-cookie-service';
import {PrivacyComponent} from './privacy/privacy.component';
import {GameComponent} from './boomgaard/game/game.component';
import {SoundComponent} from './boomgaard/sound/sound.component';
import {EndComponent} from './boomgaard/end/end.component';
import {TipsComponent} from './boomgaard/tips/tips.component';
import {DownloadComponent} from './boomgaard/download/download.component';
import {HomePageComponent} from './home-page/home-page.component';

const routes: Routes = [
  {
    path: 'degoudendief',
    loadChildren: () => import('./gouden-dief/gouden-dief.module').then(m => m.GoudenDiefModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'wensboom',
    loadChildren: () => import('./wensboom/wensboom.module').then(m => m.WensboomModule)
  },
  {
    path: 'leuven/wensboom',
    loadChildren: () => import('./wensboom-leuven/wensboom-leuven.module').then(m => m.WensboomLeuvenModule)
  },
  { path: 'delegendevanzandhoven', component: LegendeZandhovenComponent },
  { path: 'home', component: HomeComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'plukdeappels', component: GameComponent },
  { path: 'deboomgaard/routebeschrijving', component: SoundComponent },
  { path: 'deboomgaard/end', component: EndComponent },
  { path: 'deboomgaard/tips', component: TipsComponent },
  { path: 'deboomgaard/e35a3b8e21', component: DownloadComponent },
  { path: '',  redirectTo: '/home', pathMatch: 'full' },
  { path: 'home-redesign', component: HomePageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    CookieService
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
