import {Component, Inject, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit {

  correctAnswers: string;
  audio = new Audio();

  constructor(private cookieService: CookieService,
              private router: Router,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.correctAnswers = this.getInputCode('correctAnswers');

    if (this.correctAnswers === 'true') {
      this.audio.src =  '../../assets/sounds/success.wav';
    } else {
      this.audio.src =  '../../assets/sounds/fail.wav';
    }

    this.audio.load();
    this.audio.play();
  }

  navigate(): void {
    this.audio.pause();
    this.document.location.href = 'https://www.a-maze-webshop.be/';
  }

  navigateBack(): void {
    this.router.navigate(['plukdeappels']);
  }

  private getInputCode(code: string): string {
    if (this.cookieService.get(code)) {
      return this.cookieService.get(code);
    } else {
      return '';
    }
  }
}
