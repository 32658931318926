import {AfterContentInit, Component, ContentChildren, QueryList} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {GridItemDirective} from './grid-item/grid-item.directive';

@Component({
  selector: 'app-grid',
  templateUrl: 'grid.component.html',
  styleUrls: ['grid.component.scss'],
})
export class GridComponent implements AfterContentInit {

  @ContentChildren(GridItemDirective, {descendants: true}) gridItems: QueryList<GridItemDirective>;

  componentColumns: Array<Array<GridItemDirective>>;
  numberOfColumns: number;

  constructor() {
  }

  ngAfterContentInit(): void {
    this.gridItems.changes
      .subscribe(() => this.componentColumns = this.groupGridItemsIntoXColumns(this.numberOfColumns));
    this.numberOfColumns = this.getNumberOfColumnsForCurrentSize();
    this.componentColumns = this.groupGridItemsIntoXColumns(this.numberOfColumns);
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(50),
        filter(() => this.numberOfColumns !== this.getNumberOfColumnsForCurrentSize()),
        tap(() => this.numberOfColumns = this.getNumberOfColumnsForCurrentSize()),
        tap(() => this.componentColumns = this.groupGridItemsIntoXColumns(this.numberOfColumns)),
      )
      .subscribe();
  }

  private getNumberOfColumnsForCurrentSize(): number {
    const currentWidth = window.innerWidth;
    if (currentWidth > 1555) {
      return 4;
    } else if (currentWidth > 1185) {
      return 3;
    }
    return 1;
  }

  private groupGridItemsIntoXColumns(numberOfColumns: number): Array<Array<GridItemDirective>> {
    const result = [];
    for (let i = 0; i < numberOfColumns; i++) {
      result.push([]);
    }

    this.gridItems.forEach((item, index) => {
      if (result[index % numberOfColumns]) {
        result[index % numberOfColumns].push(item);
      }
    });

    return result;
  }
}
