import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameComponent } from './game/game.component';
import { SoundComponent } from './sound/sound.component';
import {ReactiveFormsModule} from '@angular/forms';
import { EndComponent } from './end/end.component';
import {TipsComponent} from './tips/tips.component';
import { DownloadComponent } from './download/download.component';

@NgModule({
  declarations: [
    GameComponent,
    SoundComponent,
    EndComponent,
    TipsComponent,
    DownloadComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class BoomgaardModule { }
