import { Component } from '@angular/core';
import {Puzzle} from './puzzle.model';
import {Tips} from './tips.model';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent {
  puzzles: Array<Puzzle> = [
    {
      name: 'kippenhok',
      title: 'IN HET KIPPENHOK',
      tips: [
        {
          description: 'Elke kip heeft één naam, één lievelingsmaaltijd, één kleur, één accessoire, één land van herkomst en haar eigen plek in het kippenhok',
          canShow: false
        },
        {
          description: 'Plaats bij elke kip de 5 juiste eigenschappen, een logigram kan jou hierbij helpen',
          canShow: false
        },
        {
          description: 'De symbolen die je terugvindt in de klok vervang je nu door het cijfer op het ei van de kip waarnaar het symbool verwijst in de tekst',
          canShow: false
        },
        {
          description: 'De eigenschappen van elke kip van links naar rechts in het kippenhok',
          canShow: false,
          extra: [
            {
              name: 'Rosa',
              description: 'Rosa heeft gele veren, eet graag vers gras, draagt een bril en is afkomstig van Frankrijk',
              canShow: false
            },
            {
              name: 'Frida',
              description: 'Frida heeft blauwe veren, eet graag broodkruimels, draagt een hoed en is afkomstig van Zweden',
              canShow: false
            },
            {
              name: 'Marjan',
              description: 'Marjan heeft rode veren, eet graag frieten, draagt geen accessoire en is afkomstig van Rusland',
              canShow: false
            },
            {
              name: 'Odette',
              description: 'Odette heeft groene veren, eet graag maïs, draagt een strikje en is afkomstig van Peru',
              canShow: false
            },
            {
              name: 'Clara',
              description: 'Clara heeft witte veren, eet graag peulvruchten, draagt een sjaal en is afkomstig van Madagaskar',
              canShow: false
            }
          ]
        },
        {
          description: 'De tijd die je vindt wanneer je de symbolen vervangt door de cijfers op de eieren van de kip is 10:27',
          canShow: false
        }
      ]
    },
    {
      name: 'tractor',
      title: 'DE TRACTOR',
      tips: [
        {
          description: 'De tractor dient als een vertaler om romeinse cijfers naar letters om te zetten',
          canShow: false
        },
        {
          description: 'De romeinse cijfers die verborgen zitten in de hekken moet je vertalen naar een zin',
          canShow: false
        },
        {
          description: 'Maak gebruik van de uitgeknipte cirkels uit het extra blad en plaats die op de wielen van de tractor zodat de pijl wijst naar een romeins cijfer en er in de opening een letter verschijnt',
          canShow: false
        },
        {
          description: 'De zin die je kan vormen dient als het vervolg van de tekst op de nummerplaat',
          canShow: false
        },
        {
          description: 'De zin die je kan vormen is plukdeappels',
          canShow: false
        }
      ]
    },
    {
      name: 'mol',
      title: 'DE BLINDE MOL',
      tips: [
        {
          description: 'Het geluidsfragment beschrijft de weg die je moet volgen door de tunnels van de mol',
          canShow: false
        },
        {
          description: 'Maak gebruik van het dunne strookje met symbolen uit het extra blad om vier letters te ontdekken in de route',
          canShow: false
        },
        {
          description: 'De plaatsnaam die je vind in de letters die ontstaan in de gangen van de mol is PELT',
          canShow: false
        }
      ]
    },
    {
      name: 'ladders',
      title: 'LADDERS OVERAL',
      tips: [
        {
          description: 'Het rooster van ladders moet ingevuld worden met woorden',
          canShow: false
        },
        {
          description: 'Gebruik de ladders die je kan terugvinden op de tekeningen om het rooster te vervolledigen, er is maar één juiste oplossing mogelijk waarin je alle woorden één keer kan gebruiken',
          canShow: false
        },
        {
          description: 'De grassen die in het rooster op een andere tekening groeien komen overeen met letters in het rooster van ladders',
          canShow: false
        },
        {
          description: 'Het woord dat je kan vormen met de letters die worden aangeduid in het rooster is druiven',
          canShow: false
        }
      ]
    },
    {
      name: 'fruitbomen',
      title: 'FRUITBOMEN',
      tips: [
        {
          description: 'De verzameling van vruchten onderaan de tekening stelt een rij van getallen voor',
          canShow: false
        },
        {
          description: 'De getallen vind je door het aantal vruchten op te tellen dat je tegenkomt in alle tekeningen',
          canShow: false
        },
        {
          description: 'Deze rij van getallen stelt een gsm-nummer voor',
          canShow: false
        },
        {
          description: 'Het gsm-nummer waarnaar je moet bellen is 0485 25 03 52',
          canShow: false
        }
      ]
    }
  ];

  toggleArrow(tip: Tips): void {
    tip.canShow = !tip.canShow;
  }

  toggleExtra(extra: any): void {
    extra.canShow = !extra.canShow;
  }
}
