import { Component, OnInit } from '@angular/core';
import {NavItem} from '../shared/nav-item.model';
import {WebshopService} from '../services/webshop.service';
import {Observable} from 'rxjs';
import {Product} from '../shared/game/product.model';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  readonly NavItem = NavItem;

  products: Observable<Array<Product>>;

  constructor(private webshopService: WebshopService) { }

  ngOnInit() {
    this.getGames();
  }

  getGames(): void {
    this.products = this.webshopService.getProducts();
  }
}
