import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Product} from '../shared/game/product.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebshopService {

  url: string = environment.backendHost + 'products';

  constructor(private httpClient: HttpClient) { }

  getProducts(): Observable<Array<Product>> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET , PUT , POST , DELETE')
      .set('API-key', 'a55f9ef7-bc38-4314-8124-a4b8b9433e9c');

    return this.httpClient.get(this.url, {headers}).pipe(
      map((products: Array<Product>) => products)
    );
  }
}
