// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBxUUtGq1B7AE37ZgD5AMyH-yJxXgtkQ2U',
    authDomain: 'database-2179d.firebaseapp.com',
    projectId: 'database-2179d',
    storageBucket: 'database-2179d.appspot.com',
    messagingSenderId: '1065672966759',
    appId: '1:1065672966759:web:ac4a376bc187ffcbcdcac2',
    measurementId: 'G-VPG9N0S2JQ'
  },
  backendHost: 'https://amaze-payments.herokuapp.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
