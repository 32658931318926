import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appGridItem]',
})
export class GridItemDirective {

  itemTemplate: TemplateRef<any>;

  constructor(private templateRef: TemplateRef<any>) {
    this.itemTemplate = this.templateRef;
  }

}
