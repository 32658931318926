import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallNavigatieComponent } from './small-navigatie/small-navigatie.component';
import { HamburgerMenuComponent } from './hamburger-menu/hamburger-menu.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { GameComponent } from './game/game.component';
import {GridItemDirective} from './grid/grid-item/grid-item.directive';
import {GridComponent} from './grid/grid.component';



@NgModule({
  declarations: [
    SmallNavigatieComponent,
    HamburgerMenuComponent,
    NavigationComponent,
    FooterComponent,
    GameComponent,
    GridComponent,
    GridItemDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SmallNavigatieComponent,
    HamburgerMenuComponent,
    NavigationComponent,
    FooterComponent,
    GameComponent,
    GridComponent,
    GridItemDirective
  ]
})
export class SharedModule { }
