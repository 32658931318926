import {Component, OnInit} from '@angular/core';
import {InputType} from './input-type.enum';
import {FormControl, FormGroup} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  readonly InputType = InputType;

  form: FormGroup;
  date = new Date();
  minutes = this.date.getMinutes();
  hours = this.date.getHours();
  inputType = InputType.Text;
  inputId = 'time';

  timeHours: FormControl;
  timeSeconds: FormControl;
  traffic: FormControl;
  label: FormControl;
  basket: FormControl;

  basketImage: string;
  isPopupOpen = false;

  constructor(private cookieService: CookieService,
              private router: Router) { }

  ngOnInit() {
    this.timeHours = new FormControl(this.getInputCode('timeHours'));
    this.timeSeconds = new FormControl(this.getInputCode('timeSeconds'));
    this.traffic = new FormControl(this.getInputCode('traffic'));
    this.label = new FormControl(this.getInputCode('label'));
    this.basket = new FormControl(this.getInputCode('basket'));

    if (this.basket.value) {
      this.setBasket();
    } else {
      this.basketImage =  '../../../assets/boomgaard/mand.svg';
    }

    this.calculateClock();

    this.form = new FormGroup({
      timeHours: this.timeHours,
      timeSeconds: this.timeSeconds,
      traffic: this.traffic,
      label: this.label,
      basket: this.basket
    });

    this.setInputCode('correctAnswers', 'false');
  }

  setClock(): void {
    const hands = [
      {
        hand: 'hours',
        angle: (this.hours * 30) + (this.minutes / 2)
      },
      {
        hand: 'minutes',
        angle: (this.minutes * 6)
      }
    ];

    for (let j = 0; j < hands.length; j++) {
      const elements = document.querySelectorAll<HTMLElement>('.' + hands[j].hand);
      for (let k = 0; k < elements.length; k++) {
        elements[k].style.webkitTransform = 'rotateZ(' + hands[j].angle + 'deg)';
        elements[k].style.transform = 'rotateZ(' + hands[j].angle + 'deg)';
        elements[k].style.transition = 'all 1s ease-out';
      }
    }
  }

  togglePopup(inputType: InputType, id: string): void {
    window.scroll(0, 0);
    this.isPopupOpen = !this.isPopupOpen;
    this.inputType = inputType;
    this.inputId = id;

    if (this.isPopupOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }

  toggleReset(id: string): void {
    switch (id) {
      case 'traffic':
        this.setInputCode('traffic', '');
        this.traffic.setValue('');
        break;
      case 'time':
        this.setInputCode('timeHours', '');
        this.setInputCode('timeMinutes', '');
        this.timeHours.setValue('');
        this.timeSeconds.setValue('');
        this.hours = 12;
        this.minutes = 0;
        this.setClock();
        break;
      case 'label':
        this.setInputCode('label', '');
        this.label.setValue('');
        break;
      case 'basket':
        this.setInputCode('basket', '');
        this.basket.setValue('');
        this.setBasket();
        break;
    }
  }

  save(): void {
    switch (this.inputId) {
      case 'time':
        this.calculateClock();
        break;
      case 'traffic':
        this.setTraffic();
        break;
      case 'label':
        this.setLabel();
        break;
      case 'basket':
        this.setBasket();
        break;
    }

    this.isPopupOpen = !this.isPopupOpen;

    if (this.isPopupOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }

  endGame(): void {
    const correctAnswers = this.basket.value.toLowerCase().trim() === 'druiven' &&
      this.label.value.toLowerCase().trim() === '0485 25 03 52' &&
      this.traffic.value.toLowerCase().trim() === 'pelt' &&
      this.timeHours.value.toLowerCase().trim() === '10'
      && this.timeSeconds.value.toLowerCase().trim() === '27';

    this.setInputCode('correctAnswers', correctAnswers.toString());
    this.router.navigate(['/deboomgaard/end']);
  }

  setInputCode(code: string, input: string): void {
    this.cookieService.set(code, input, 7);
  }

  getPlaceholder(id: string): string {
    switch (id) {
      case 'traffic':
        return 'X1 X2 X3 X4';
      case 'label':
        return 'Nummer';
      case 'basket':
        return 'Bestelling';
    }
  }

  private setBasket(): void {
    this.setInputCode('basket', this.basket.value.toLowerCase());

    switch (this.basket.value.toLowerCase().trim()) {
      case 'appels':
        this.basketImage = '../../../assets/boomgaard/mand_appel.svg';
        break;
      case 'avocado\'s':
        this.basketImage = '../../../assets/boomgaard/mand_avocado.svg';
        break;
      case 'bananen':
        this.basketImage = '../../../assets/boomgaard/mand_banaan.svg';
        break;
      case 'citroenen':
        this.basketImage = '../../../assets/boomgaard/mand_citroen.svg';
        break;
      case 'druiven':
        this.basketImage = '../../../assets/boomgaard/mand_druif.svg';
        break;
      case 'peren':
        this.basketImage = '../../../assets/boomgaard/mand_peer.svg';
        break;
      default:
        this.basketImage = '../../../assets/boomgaard/mand_error.svg';
    }
  }

  private getInputCode(code: string): string {
    if (this.cookieService.get(code)) {
      return this.cookieService.get(code);
    } else {
      return '';
    }
  }

  private setLabel(): void {
    this.setInputCode('label', this.label.value.toLowerCase());
  }

  private setTraffic(): void {
    this.setInputCode('traffic', this.traffic.value.toLowerCase());
  }

  private calculateClock(): void {
    this.hours = this.timeHours.value;
    this.minutes = this.timeSeconds.value;

    this.setClock();
    this.setInputCode('timeHours', this.timeHours.value);
    this.setInputCode('timeSeconds', this.timeSeconds.value);
  }
}
