import { Component, OnInit } from '@angular/core';
import {GoogleAnalyticsService} from '../../shared/google-analytics.service';
declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.downloadPdf();
  }

  private downloadPdf() {
    const pdfUrl = './assets/boomgaard.pdf';
    const pdfName = 'de_boomgaard.pdf';
    FileSaver.saveAs(pdfUrl, pdfName);
    this.downloadEvent();
  }

  downloadEvent() {
    this.googleAnalyticsService
      .eventEmitter('dowload_boomgaard', 'boomgaard', 'download', 'click', 10);
  }

}
